import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

class GalleryWork extends React.Component {
  render() {
    const { data } = this.props
    const gallerySample = getImage(data.gallerySample.childImageSharp)

    return (
      <Layout isHome={false}>
        <section
          className="content-section text-center"
          style={{
            paddingTop: '5%',
            backgroundColor: 'rgb(255, 255, 255, 0.15)',
          }}
        >
          <Container>
            <Row
              style={{
                border: 'solid #0099cc',
                margin: '10px',
                padding: '10px',
              }}
            >
              <h3 className="projectTitle"> Photo Gallery V2 </h3>
              <Row className="row-img">
                <GatsbyImage image={gallerySample} alt="image of web photogallery" style={{ marginLeft: '5%', marginRight: '5%' }} />
              </Row>
              <Row>
                <p>
                  After trying my hand at using{' '}
                  <a href="https://gatsby.org">Gatsby.js</a> for front end
                  development, I ended up making a slow but usable experience
                  for displaying photos of my past
                  <a href="https://github.com/yourkingnico/photoGallery">
                    {' '}
                    camping trips
                  </a>{' '}
                  to the Boundary Waters Canoe Area. Unsatisfied with this, I
                  set out to learn and utilize Gatsby resources to accomplish my
                  goals the Gatsby way. On my second attempt I put together a
                  much faster implementation using gatsby-image,
                  gatsby-plugin-sharp, and gatsby-transformer-sharp. These
                  plugins allowed me to utilize graphql while also resizing and
                  compressing images. This provided a better user experience by
                  utlizing the blur effect until the images load asynchronously.
                  The end result turned out much better and can be viewed
                  <a href="https://nicktorres.net/galleryDirectory"> here</a>.
                  Interested in the code? Check out the updated react components
                  <a href="https://github.com/yourkingnico/photoGalleryV2">
                    {' '}
                    here
                  </a>
                  .
                </p>
              </Row>
              <Row>
                <h4>Tools used:</h4>
                <p>
                  Gatsby.js, React.js, graphql, Javascript, Yarn,
                  react-bootstrap, Css, Scss, Html, Netlify, Github
                </p>
              </Row>
            </Row>
          </Container>
        </section>
      </Layout>
    );
  }
}

export default GalleryWork

export const query = graphql`
  query GalleryWorkQuery {
    gallerySample: file(name: { eq: "gallerySample" }) {
      childImageSharp {
        gatsbyImageData (
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
  }
`
